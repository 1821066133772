<template>
    <div>
        <CCard class="p-4"> 
            <CRow><CButton size="sm" class="btn btn-link back-btn"
				@click="backToTable()"><i class="fa fa-arrow-left"></i> Back</CButton>
			</CRow>
			<div class="pl-3 pr-3 pb-3"> 
			<CCard class="p-4">

				<CRow class="">
	        		<CCol lg="12">
	        			<h5>Personnel Renewal Reminder</h5> <hr>
	        		</CCol>
	        		
	        	</CRow>

				<CRow class="">
	        		<CCol lg="12">
	        			<h5 style="color: blue;">
							{{ dataParams.personnel ? dataParams.personnel.name:'-' }}
						</h5>
	        		</CCol>
	        		
	        	</CRow>
        		
				<CRow class="">
					
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Renewal Type </label>
	        		</CCol>
	        		<CCol lg="8" class="text-capitalize">
	        			{{ dataParams.license_renewal_type.setting_name }}
	        		</CCol>
				</CRow>
				<CRow class="">
					
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Due Date</label>
	        		</CCol>
				
	        		<CCol lg="8">
	        			{{ dataParams.due_date }}
						
	        		</CCol>
				</CRow>
				<CRow class="">
					
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Time Due Soon Thresold</label>
	        		</CCol>
				
	        		<CCol lg="8">
	        			{{ dataParams.time }} {{ dataParams.threshold_unit }}
						
	        		</CCol>
				</CRow>
				<CRow class="">
					
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Notified Email </label>
	        		</CCol>
	        		<CCol lg="8">
	        			{{ dataParams.emails }}
	        		</CCol>
				</CRow>
				<CRow class="">
					
	        		<CCol lg="3">
	        			<label style="font-weight: 600;"> Remarks </label>
	        		</CCol>
	        		<CCol lg="8" >
	        			{{ dataParams.remarks ? dataParams.remarks : "-" }}
	        		</CCol>
				</CRow>
			</CCard>
        	</div>
        </CCard>
    </div>
</template>
<script>
import config from '../../config.js';
import axios from '../../axios';
import moment from 'moment';
export default {
	mounted(){  
		this.getData();
	},
	data(){
		return{
			moment,
			config,
			options:'',
			title:'',
			isLoading: false,
			dataParams: {
				id:'',
				personnel_id:'',
				presonnel:[],
				license_renewal_type_id:'',
				license_renewal_type:[],
				threshold_unit:'',
				due_date:'',
				time:'',
				emails:'',
				remarks:'',
			},
			selectedRow: {},
		}
	},
	name: 'Tables',
	components: { },
	methods: { 
		 backToTable(){
	    	this.$router.push('/data/license_renewal_reminder')
	    },
		getData(){
			console.log('run');
			this.$showLoading(true)
			axios.get(config.api_path+"/license-renewal-reminder/"+this.$route.params.id)
			.then(response=>{
				this.$showLoading(false)
				this.dataParams = response.data.data
			})
			.catch(err => {
			this.$showLoading(false)
			}) 
		}
 	}
}
</script>
